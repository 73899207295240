import React from 'react'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { contentfulImageLoader } from '@/utils/ImageLoaders'
import { PagesPressBanner } from 'types/generated/contentful-types'

export type PressBannerProps = Omit<PagesPressBanner, '_id'> &
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >

export const pressBannerStaticData: PressBannerProps = {
  title: 'Sb',
  applyBackground: true,
  contentfulMetadata: { tags: [] },
  logoSize: false,
  sys: {
    id: '',
    environmentId: '',
    spaceId: '',
  },
  logosCollection: {
    limit: 0,
    skip: 0,
    total: 0,
    items: [
      {
        url: '/images/press/Boston-Globe.svg',
        title: 'Boston Globe',
        description: 'Boston Globe',
        contentfulMetadata: {
          tags: [],
        },
        sys: {
          id: '',
          environmentId: '',
          spaceId: '',
        },
      },
      {
        url: '/images/press/Business-Insider.svg',
        title: 'Business Insider',
        description: 'Business Insider',
        contentfulMetadata: {
          tags: [],
        },
        sys: {
          id: '',
          environmentId: '',
          spaceId: '',
        },
      },
      {
        url: '/images/press/New-York-Mag.svg',
        title: 'New York Magazine',
        description: 'New York Magazine',
        contentfulMetadata: {
          tags: [],
        },
        sys: {
          id: '',
          environmentId: '',
          spaceId: '',
        },
      },
      {
        url: '/images/press/Real-Simple.svg',
        title: 'Real Simple',
        description: 'Real Simple',
        contentfulMetadata: {
          tags: [],
        },
        sys: {
          id: '',
          environmentId: '',
          spaceId: '',
        },
      },
      {
        url: '/images/press/Tech-Crunch.svg',
        title: 'Tech Crunch',
        description: 'Tech Crunch',
        contentfulMetadata: {
          tags: [],
        },
        sys: {
          id: '',
          environmentId: '',
          spaceId: '',
        },
      },
      {
        url: '/images/press/WSJ.svg',
        title: 'WSJ',
        description: 'WSJ',
        contentfulMetadata: {
          tags: [],
        },
        sys: {
          id: '',
          environmentId: '',
          spaceId: '',
        },
      },
    ],
  },
}

type SlidesProps = Pick<PressBannerProps, 'logosCollection'> & {
  className?: string
  largeLogo: boolean
}

const Slides = ({
  logosCollection,
  className,
  largeLogo = false,
}: SlidesProps): JSX.Element => {
  if (!logosCollection) return <></>

  return (
    <ul
      className={`logos-slider inline-flex ${
        largeLogo ? 'h-[88px]' : 'h-[57px]'
      } w-max pb-2 mb-8 md:pb-0 md:mb-0 ${className}`}
    >
      {logosCollection.items.map((image, index) => (
        <li
          key={index}
          className="inline-flex justify-center mx-3 my-3.5 lg:mx-6 xl:w-auto"
        >
          <Image
            loader={() =>
              contentfulImageLoader({
                src: image?.url || '',
                width: largeLogo ? 107 : 61,
                height: largeLogo ? 72 : 40,
                quality: 90,
                fit: 'fill',
              })
            }
            src={image?.url || ''}
            alt={image?.description || `Fora - ${image?.title || ''}`}
            width={largeLogo ? 107 : 61}
            height={largeLogo ? 72 : 40}
            className="mix-blend-darken"
            priority // Lazy loading causes rendering issues for infinitely looping slider
          />
        </li>
      ))}

      <style jsx>{`
        @keyframes autoplay {
          from {
            transform: translate3d(0%, 0, 0);
          }
          to {
            transform: translate3d(-100%, 0, 0);
          }
        }
        .logos-slider {
          backface-visibility: hidden;
          transform: translate3d(0, 0, 0);
          animation: ${logosCollection.items.length * 3}s autoplay infinite
            linear;
        }
      `}</style>
    </ul>
  )
}

const PressBanner = ({
  id,
  title,
  applyBackground = true,
  logosCollection,
  redirectSlug = '',
  logoSize = false,
}: PressBannerProps): JSX.Element => {
  const headerStyles =
    'text-center uppercase fora-text-eyebrow-4 text-stone mb-4 md:mb-6'
  const largeLogo = logoSize

  return (
    <>
      {redirectSlug ? (
        <Link href={redirectSlug} legacyBehavior>
          <a id={id} className="block">
            <h3 className={headerStyles}>{title}</h3>
            <PressBannerContentBlock
              largeLogo={largeLogo as boolean}
              applyBackground={applyBackground}
              logosCollection={logosCollection}
            />
          </a>
        </Link>
      ) : (
        <div id={id} className="block">
          <h3 className={`${headerStyles} cursor-default`}>{title}</h3>
          <PressBannerContentBlock
            largeLogo={largeLogo as boolean}
            applyBackground={applyBackground}
            logosCollection={logosCollection}
          />
        </div>
      )}
    </>
  )
}

type PressBannerContentBlockProps = Pick<
  PressBannerProps,
  'logosCollection'
> & {
  applyBackground: boolean | null
  largeLogo: boolean
}

const PressBannerContentBlock = ({
  applyBackground,
  logosCollection,
  largeLogo,
}: PressBannerContentBlockProps): JSX.Element => {
  return (
    <>
      <section
        className={`overflow-hidden whitespace-nowrap ${
          largeLogo ? 'h-[88px]' : 'h-[57px]'
        }`}
      >
        <div className="lg:w-screen lg:absolute lg:left-0 lg:overflow-hidden">
          <Slides
            largeLogo={largeLogo}
            logosCollection={logosCollection}
            className={applyBackground ? 'bg-darkSand' : ''}
          />
          {/* Triplicate slides to loop seamlessly */}
          <Slides
            largeLogo={largeLogo}
            logosCollection={logosCollection}
            className={`${applyBackground ? 'bg-darkSand' : ''}`}
          />
          <Slides
            largeLogo={largeLogo}
            logosCollection={logosCollection}
            className={`${applyBackground ? 'bg-darkSand' : ''}`}
          />
        </div>
      </section>
    </>
  )
}

export default PressBanner
